import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Alert, Table, Modal, Row, Col, Select } from 'antd'; // Importe apenas do Ant Design
import { FiSearch } from 'react-icons/fi';
import { fetchUsers, updateUser } from '../redux/actions/userActions';
import './NovoAluno.css';
import axios from 'axios';
import { setUsers } from '../redux/actions/userActions.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import validator from 'validator';

const { Option } = Select;

const AdicionarAlunos = () => {
  const dispatch = useDispatch();
  const usuarios = useSelector(state => state.user.users);

  const [novaEmpresa, setNovaEmpresa] = useState({
    cnpj: '',
    nome: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
    telefone: '',
    responsavel: '',
    email: '',
    senha: '',
  });

  const [formEmpresa] = Form.useForm();

  const handleSubmitEmpresa = async (values) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Updateempresas`, values);
      if (response.data.success) {
        toast.success("Empresa cadastrada com sucesso!");
        formEmpresa.resetFields();
      } else {
        if (response.data.message) {
          toast.error(response.data.message);
        } else {
          toast.error("Erro ao cadastrar empresa.");
        }
      }
    } catch (error) {
      console.error('Erro ao enviar os dados da nova empresa:', error);
      toast.error("Erro ao cadastrar empresa. Verifique o console para mais detalhes.");
    }
  };

  const [newAluno, setNewAluno] = useState({
    empresa: '',
    username: '',
    nome: '',
    sobrenome: '',
    email: '',
    role: 'Aluno',
    senha: '',
  });

  const [selectedEmpresa, setSelectedEmpresa] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [editableUser, setEditableUser] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  // Estado para empresas
  const [empresas, setEmpresas] = useState([]);
  const [searchTermEmpresa, setSearchTermEmpresa] = useState('');
  const [showEmpresaModal, setShowEmpresaModal] = useState(false);
  const [editableEmpresa, setEditableEmpresa] = useState({});
  const [showDeleteEmpresaModal, setShowDeleteEmpresaModal] = useState(false);
  const [empresaToDelete, setEmpresaToDelete] = useState(null);

  const [formAluno] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add-aluno`, values);
      if (response.data.success) {
        toast.success('Aluno adicionado com sucesso!');
        formAluno.resetFields();
        dispatch(fetchUsers());
      } else {
        toast.error('Erro ao adicionar aluno.');
      }
    } catch (error) {
      console.error('Erro ao enviar os dados do novo aluno:', error);
      toast.error('Erro ao adicionar aluno. Verifique o console para mais detalhes.');
    }
  };

  // Funções para empresas
  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/empresas`);
        setEmpresas(response.data);
      } catch (error) {
        console.error('Erro ao buscar empresas:', error);
      }
    };
    fetchEmpresas();
  }, []);

  const handleEmpresaSearch = (e) => {
    setSearchTermEmpresa(e.target.value);
  };

  const handleEditEmpresa = (id) => {
    const empresa = empresas.find(emp => emp.id === id);
    if (empresa) {
      setEditableEmpresa(empresa);
      formEmpresa.setFieldsValue(empresa);
      setShowEmpresaModal(true);
    } else {
      console.error("Empresa selecionada não encontrada.");
    }
  };

  useEffect(() => {
    if (editableEmpresa) {
      formEmpresa.setFieldsValue(editableEmpresa);
    }
  }, [editableEmpresa, formEmpresa]);

  const handleDeleteEmpresa = (empresaId) => {
    setEmpresaToDelete(empresaId);
    setShowDeleteEmpresaModal(true);
  };

  const confirmDeleteEmpresa = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/empresas/${empresaToDelete}`);
      if (response.data.success) {
        setEmpresas(empresas.filter(emp => emp.id !== empresaToDelete));
        setShowDeleteEmpresaModal(false);
        toast.success("Empresa excluída com sucesso.");
      } else {
        toast.error("Erro ao excluir empresa.");
      }
    } catch (error) {
      console.error('Erro ao excluir empresa:', error);
      toast.error("Erro ao excluir empresa.");
    }
  };

  const cancelDeleteEmpresa = () => {
    setShowDeleteEmpresaModal(false);
  };

  const handleEmpresaChange = (e) => {
    const { name, value } = e.target;
    setEditableEmpresa({
      ...editableEmpresa,
      [name]: value,
    });
  };

  const handleUpdateEmpresa = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/empresas/${editableEmpresa.id}`, editableEmpresa);
      if (response.data.success) {
        const updatedEmpresas = empresas.map(emp =>
          emp.id === editableEmpresa.id ? editableEmpresa : emp
        );
        setEmpresas(updatedEmpresas);
        setShowEmpresaModal(false);
        toast.success("Empresa atualizada com sucesso!");
      } else {
        toast.error("Erro ao atualizar empresa.");
      }
    } catch (error) {
      console.error('Erro ao atualizar empresa:', error);
      toast.error("Erro ao atualizar empresa. Verifique o console para mais detalhes.");
    }
  };

  // Filtragem de empresas
  const filteredEmpresas = empresas.filter(empresa =>
    (empresa.nome && empresa.nome.toLowerCase().includes(searchTermEmpresa.toLowerCase())) ||
    (empresa.email && empresa.email.toLowerCase().includes(searchTermEmpresa.toLowerCase()))
  );

  // Função chamada quando o botão de exclusão é clicado
  const handleDelete = (userId) => {
    setUserToDelete(userId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-aluno/${userToDelete}`);
      if (response.data.success) {
        dispatch(fetchUsers());
        setShowDeleteModal(false);
        toast.success("Exclusão efetuada com sucesso.");
      } else {
        toast.error("Erro ao excluir aluno.");
      }
    } catch (error) {
      console.error('Erro ao excluir aluno:', error);
      toast.error("Erro ao excluir aluno.");
    }
  };

  // Função para cancelar a exclusão
  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableUser({
      ...editableUser,
      [name]: value,
    });
  };

  const [form] = Form.useForm();

  const handleEdit = (email) => {
    const selectedUser = filteredUsuarios.find(u => u.email === email);
    if (selectedUser) {
      setEditableUser(selectedUser);
      form.setFieldsValue(selectedUser);
      setShowModal(true);
    } else {
      console.error("Usuário selecionado não encontrado.");
    }
  };

  useEffect(() => {
    if (editableUser) {
      form.setFieldsValue(editableUser);
    }
  }, [editableUser, form]);

  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/empresas`);
        setEmpresas(response.data);
      } catch (error) {
        console.error('Erro ao buscar empresas:', error);
      }
    };
    fetchEmpresas();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filtragem de usuários
  const filteredUsuarios = (usuarios || []).filter(usuario =>
    (usuario.nome && usuario.nome.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (usuario.email && usuario.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const user = filteredUsuarios.find((usuario) => usuario.email === selectedUserEmail);

  const carregarUsuarios = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/alunos`);
      dispatch(setUsers(response.data || []));
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setEditableUser({
      ...editableUser,
      [name]: value,
    });
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    if (!editableUser.id) {
      console.error('ID do usuário não está presente.');
      return;
    }

    const userData = {
      userId: editableUser.id,
      empresa: editableUser.empresa,
      nome: editableUser.nome,
      sobrenome: editableUser.sobrenome,
      email: editableUser.email,
      endereco: editableUser.endereco,
      cidade: editableUser.cidade,
      cep: editableUser.cep,
      pais: editableUser.pais,
      role: editableUser.role,
      username: editableUser.username,
    };

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/profileEdit`, userData);
      if (response.data.success) {
        setShowModal(false);
        dispatch(updateUser(userData));
        dispatch(fetchUsers());
      } else {
        alert('Erro ao atualizar usuário.');
      }
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      alert('Erro ao atualizar usuário. Verifique o console para mais detalhes.');
    }
  };

  useEffect(() => {
    carregarUsuarios();
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem('selectedUserEmail');
    if (storedEmail) {
      setSelectedUserEmail(storedEmail);
    }
  }, []);

  const handleNewAlunoChange = (e) => {
    const { name, value } = e.target;
    setNewAluno({
      ...newAluno,
      [name]: value,
    });
  };

  // Função de validação personalizada para CNPJ
  const validateCNPJ = (_, value) => {
    if (!value || validator.isTaxID(value, 'pt-BR')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Por favor, insira um CNPJ válido'));
  };

  // Função de validação personalizada para telefone
  const validatePhone = (_, value) => {
    if (!value || validator.isMobilePhone(value, 'pt-BR')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Por favor, insira um número de telefone válido'));
  };

  return (
    <Row justify="center" className="mt-4">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <Col xs={24} lg={18}>
        <center><div className='back'><h1 style={{ fontFamily: 'Montserrat', fontSize: '28pt' }}>Gerenciamento de Alunos</h1></div></center>

        <div className='back1'>
          <div style={{ position: 'relative' }}>
            <Input
              type="text"
              placeholder="Pesquisar por nome ou email"
              value={searchTerm}
              onChange={handleSearch}
              className="rounded-pill search-input"
              suffix={<FiSearch style={{ fontSize: '20px' }} />}
            />
          </div>
        </div>

        <div className='back1'>
          <Table striped bordered hover className="m-0 table-users" dataSource={filteredUsuarios} rowKey="id">
            <Table.Column title="Nome" dataIndex="nome" key="nome" />
            <Table.Column title="Email" dataIndex="email" key="email" />
            <Table.Column
              title="Ações"
              key="actions"
              render={(text, record) => (
                <span>
                  <Button variant="light" onClick={() => handleEdit(record.email)}>
                    <img src="https://imgur.com/eIhi3oc.png" alt="Editar" width="24" height="24" />
                  </Button>
                  <Button variant="danger" onClick={() => handleDelete(record.id)}>
                    <img src="https://imgur.com/WkU1RFZ.png" alt="Excluir" width="24" height="24" />
                  </Button>
                </span>
              )}
            />
          </Table>
        </div>

        <hr />
        <center><div className='back'><h1 style={{ fontFamily: 'Montserrat', fontSize: '28pt' }}>Gerenciamento de Empresas</h1></div></center>
        <div className='back1'>
          <div style={{ position: 'relative' }}>
            <Input
              type="text"
              placeholder="Pesquisar por nome ou email da empresa"
              value={searchTermEmpresa}
              onChange={handleEmpresaSearch}
              className="rounded-pill search-input"
              suffix={<FiSearch style={{ fontSize: '20px' }} />}
            />
          </div>
        </div>

        <div className='back1'>
          <Table striped bordered hover className="m-0 table-users" dataSource={filteredEmpresas} rowKey="id">
            <Table.Column title="Nome" dataIndex="nome" key="nome" />
            <Table.Column title="Email" dataIndex="email" key="email" />
            <Table.Column title="CNPJ" dataIndex="cnpj" key="cnpj" />
            <Table.Column
              title="Ações"
              key="actions"
              render={(text, record) => (
                <span>
                  <Button variant="light" onClick={() => handleEditEmpresa(record.id)}>
                    <img src="https://imgur.com/eIhi3oc.png" alt="Editar" width="24" height="24" />
                  </Button>
                  <Button variant="danger" onClick={() => handleDeleteEmpresa(record.id)}>
                    <img src="https://imgur.com/WkU1RFZ.png" alt="Excluir" width="24" height="24" />
                  </Button>
                </span>
              )}
            />
          </Table>
        </div>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <div className='back1'>
              <center><h6>Adicionar Novo Aluno</h6></center>
              <Form form={formAluno} onFinish={handleSubmit} layout="vertical">
                <Form.Item label="Empresa" name="empresa">
                  <Select placeholder="Selecione a empresa" allowClear>
                    {empresas.map(empresa => (
                      <Option key={empresa.id} value={empresa.nome}>{empresa.nome}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Nome de usuário" name="username" rules={[{ required: true, message: 'Por favor, insira o nome de usuário' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Primeiro Nome" name="nome" rules={[{ required: true, message: 'Por favor, insira o primeiro nome' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Sobrenome" name="sobrenome" rules={[{ required: true, message: 'Por favor, insira o sobrenome' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Por favor, insira um email válido' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Senha" name="senha" rules={[{ required: true, message: 'Por favor, insira a senha' }]}>
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn-suus" style={{ backgroundColor: '#FF7F00' }}>
                    Adicionar Aluno
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={12}>
            <div className='back1'>
              <center><h6>Cadastrar Nova Empresa</h6></center>
              <Form form={formEmpresa} onFinish={handleSubmitEmpresa} layout="vertical">
                <Form.Item label="CNPJ" name="cnpj" rules={[{ required: true, message: 'Por favor, insira o CNPJ' }, { validator: validateCNPJ }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Nome da Empresa" name="nome" rules={[{ required: true, message: 'Por favor, insira o nome da empresa' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Logradouro" name="logradouro" rules={[{ required: true, message: 'Por favor, insira o logradouro' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Número" name="numero" rules={[{ required: true, message: 'Por favor, insira o número' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Complemento" name="complemento">
                  <Input />
                </Form.Item>
                <Form.Item label="Bairro" name="bairro" rules={[{ required: true, message: 'Por favor, insira o bairro' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Cidade" name="cidade" rules={[{ required: true, message: 'Por favor, insira a cidade' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Estado" name="estado" rules={[{ required: true, message: 'Por favor, insira o estado' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="CEP" name="cep" rules={[{ required: true, message: 'Por favor, insira o CEP' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Telefone" name="telefone" rules={[{ required: true, message: 'Por favor, insira o telefone' }, { validator: validatePhone }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Por favor, insira um email válido' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Senha" name="senha" rules={[{ required: true, message: 'Por favor, insira a senha' }]}>
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn-suus" style={{ backgroundColor: '#FF7F00' }}>
                    Cadastrar Empresa
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>

        <Modal
          title="Editar Aluno"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={[
            <Button key="back" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={() => form.submit()}>
              Salvar
            </Button>,
          ]}
        >
          <Form form={form} onFinish={handleUpdateUser} layout="vertical">
            <Form.Item label="ID" name="id">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Empresa" name="empresa">
              <Input />
            </Form.Item>
            <Form.Item label="Username" name="username">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Por favor, insira o nome' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Sobrenome" name="sobrenome" rules={[{ required: true, message: 'Por favor, insira o sobrenome' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Por favor, insira um email válido' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Endereço" name="endereco">
              <Input />
            </Form.Item>
            <Form.Item label="Cidade" name="cidade">
              <Input />
            </Form.Item>
            <Form.Item label="CEP" name="cep">
              <Input />
            </Form.Item>
            <Form.Item label="País" name="pais">
              <Input />
            </Form.Item>
            <Form.Item label="Role" name="role">
              <Input disabled />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Excluir Aluno"
          visible={showDeleteModal}
          onCancel={cancelDelete}
          footer={[
            <Button key="back" onClick={cancelDelete}>
              Cancelar
            </Button>,
            <Button key="submit" type="danger" onClick={confirmDelete}>
              Sim, quero excluir
            </Button>,
          ]}
        >
          <p>Deseja excluir o aluno? Isso não é reversível.</p>
        </Modal>

        <Modal
          title="Dados da Empresa"
          visible={showEmpresaModal}
          onCancel={() => setShowEmpresaModal(false)}
          footer={[
            <Button key="back" onClick={() => setShowEmpresaModal(false)}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" style={{ backgroundColor: '#FF7F00' }} onClick={() => formEmpresa.submit()}>
              Salvar
            </Button>,
          ]}
        >
          <Form form={formEmpresa} onFinish={handleUpdateEmpresa} layout="vertical">
            <Form.Item label="CNPJ" name="cnpj" rules={[{ required: true, message: 'Por favor, insira o CNPJ' }, { validator: validateCNPJ }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Nome da Empresa" name="nome" rules={[{ required: true, message: 'Por favor, insira o nome da empresa' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Logradouro" name="logradouro" rules={[{ required: true, message: 'Por favor, insira o logradouro' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Número" name="numero" rules={[{ required: true, message: 'Por favor, insira o número' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Complemento" name="complemento">
              <Input />
            </Form.Item>
            <Form.Item label="Bairro" name="bairro" rules={[{ required: true, message: 'Por favor, insira o bairro' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Cidade" name="cidade" rules={[{ required: true, message: 'Por favor, insira a cidade' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Estado" name="estado" rules={[{ required: true, message: 'Por favor, insira o estado' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="CEP" name="cep" rules={[{ required: true, message: 'Por favor, insira o CEP' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Telefone" name="telefone" rules={[{ required: true, message: 'Por favor, insira o telefone' }, { validator: validatePhone }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Por favor, insira um email válido' }]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Excluir Empresa"
          visible={showDeleteEmpresaModal}
          onCancel={cancelDeleteEmpresa}
          footer={[
            <Button key="back" onClick={cancelDeleteEmpresa}>
              Cancelar
            </Button>,
            <Button key="submit" type="danger" onClick={confirmDeleteEmpresa}>
              Excluir
            </Button>,
          ]}
        >
          <p>Tem certeza de que deseja excluir esta empresa? Esta ação é irreversível.</p>
        </Modal>
      </Col>
    </Row>
  );
};

export default AdicionarAlunos;